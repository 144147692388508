import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

class AwardsPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location="./awards" title={siteTitle}>
        <SEO title="Awards" keywords={[`painting`, `historical`]} />
        <div>
          <h3>Awards</h3>
          <ul>
            <li>
              “Detroit Home Awards” 2006 Winner for color scheme/use of color
            </li>
            <li>
              American Bungalow Magazine feature article “Love in a Michigan
              Bungalow” May 2004
            </li>
          </ul>
          <br />
          <h3>Affiliations</h3>
          <ul>
            <li>
              Michigan Historic Preservation Network -{" "}
              <a href="www.mhpn.org">www.mhpn.org</a>
            </li>
            <li>
              Preservation Wayne -{" "}
              <a href="www.preservationwayne.org">www.preservationwayne.org</a>
            </li>
            <li>
              Tecumseh Historical Society -{" "}
              <a href="www.historictecumseh.org">www.historictecumseh.org</a>
            </li>
          </ul>
          <br />
          <h3>Profession Organizations and Partnerships</h3>
          <ul>
            <li>
              Weatherford Design Co. -{" "}
              <a href="www.weatherforddesign.com">www.weatherforddesign.com</a>
            </li>
          </ul>
        </div>
      </Layout>
    );
  }
}

export default AwardsPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
